import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  html: "",
  news: [],
  events: [],
  languages: ["rus", "est"],
}

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setNews: (state, action) => {
      state.news = action.payload
    },
    setEvents: (state, action) => {
      state.events = action.payload
    },
  },

  extraReducers: (builder) => {},
})

export const { setNews, setEvents } = dataSlice.actions
export default dataSlice.reducer
