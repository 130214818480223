import React, { useState } from "react"
import "bulma/css/bulma.min.css"
import { useNavigate } from "react-router"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { is } from "immutable"

const Header = () => {
  const [isActive, setIsActive] = useState(false)
  const { t, i18n } = useTranslation()
  const isEe = i18n.language === "est"
  const languages = useSelector((state) => state.data.languages)
  const localLang = localStorage.getItem("language")
  const navigate = useNavigate()
  const toggleBurgerMenu = () => {
    setIsActive(!isActive)
  }

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <div
          className="navbar-item"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/")
          }}
        >
          <img src={"https://doronin.tomberg.tech/images/26.png"} alt="" />
        </div>
        <a
          role="button"
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded={isActive ? "true" : "false"}
          data-target="navbarBasicExample"
          onClick={toggleBurgerMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarBasicExample"
        className={`navbar-menu ${isActive ? "is-active" : ""}`}
      >
        <div className="navbar-start is-size-3">
          <a
            onClick={() => {
              navigate("/")
            }}
            className={`navbar-item ${isEe ? "bold" : ""}`}
          >
            {t("home")}
          </a>

          <a
            className={`navbar-item ${isEe ? "bold" : ""}`}
            onClick={() => {
              navigate("/news")
            }}
          >
            {t("news")}
          </a>
          <a
            onClick={() => {
              navigate("/schedule")
            }}
            className={`navbar-item ${isEe ? "bold" : ""}`}
          >
            {t("schedule")}
          </a>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <a
                className="button is-primary"
                onClick={() => {
                  navigate("/pray-request")
                }}
              >
                <strong>{t("prayRequest")}</strong>
              </a>
              <a className="button is-primary">
                <strong>{t("donate")}</strong>
              </a>
              <div className="select is-rounded">
                <select
                  value={localLang ? localLang : "est"}
                  onChange={(event) => {
                    const lang = event.target.value
                    localStorage.setItem("language", lang)
                    i18n.changeLanguage(lang)
                  }}
                >
                  {languages.map((lang) => {
                    return (
                      <option key={lang} value={lang}>
                        {lang.toUpperCase()}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
