import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";

function NewsItem() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("language") || "est";
  const news = location.state;
  const isEe = i18n.language === "est";

  return (
    <div>
      <h1 className='is-size-5'>{isEe ? news.title_ee : news.title_ru}</h1>
      <p className='is-size-5'>
        {isEe ? news.description_ee : news.description_ru}
      </p>
      <img src={news.preview} alt='News Image' style={{ width: "500px" }} />
      <div
        dangerouslySetInnerHTML={{
          __html: isEe ? news.content_ee : news.content_ru,
        }}
      ></div>
    </div>
  );
}

export default NewsItem;
