import React from "react"
import { post } from "../utils/axios_utils"
import { FILE } from "../utils/constants"
import { toast } from "react-toastify"
import { createNotification } from "../utils/util_funcs"
import FileInput from "./FileInput"

function UploadImage() {
  const [file, setFile] = React.useState(null)

  async function handleUpload(e) {
    e.preventDefault()
    const formData = new FormData()
    if (!file) {
      createNotification(toast, "ERROR", "No file selected")()
      return
    }
    formData.append("file", file)
    console.log(formData)
    await post(FILE.UPLOAD, toast, formData)
    file.value = ""
  }

  return (
    <div className="column is-one-third">
      <div className="columns">
        <FileInput selectedFile={file} setSelectedFile={setFile} />
        <div className="column is-one-second">
          <button
            className="button is-primary mt-2"
            onClick={(e) => handleUpload(e)}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  )
}

export default UploadImage
