import React, { useEffect } from "react"
import { useSelector } from "react-redux"

function Home() {
  const news = useSelector((state) => state.data.news)

  return (
    <div style={{ padding: "20px" }}>
      <img
        className="home-image"
        src="https://doronin.tomberg.tech/images/27.jpg"
        alt=""
      />
    </div>
  )
}

export default Home
