import React from "react"
import { useSelector } from "react-redux"
import { formatDate } from "../utils/util_funcs"
import { useTranslation } from "react-i18next"

function Schedule() {
  const events = useSelector((state) => state.data.events)
  const { t, i18n } = useTranslation()
  const isEe = i18n.language === "est"
  return (
    <table className="table is-striped is-hoverable is-fullwidth is-size-3 is-size-5-mobile is-size-4-tablet is-size-3-desktop">
      <thead>
        <tr>
          <th style={{ textAlign: "center" }}>{t("date")}</th>
          <th style={{ textAlign: "center" }}>{t("time")}</th>
          <th style={{ textAlign: "center" }}>{t("dayOfWeek")}</th>
          <th style={{ textAlign: "center" }}>{t("eventLabel")}</th>
        </tr>
      </thead>
      <tbody>
        {events.map((item, index) => {
          if (item.active) {
            return (
              <tr key={index}>
                <td>{formatDate(new Date(item.dateTime), "date")}</td>
                <td>{formatDate(new Date(item.dateTime), "time")}</td>
                <td>{formatDate(new Date(item.dateTime), "dayOfWeek")}</td>
                <td>{isEe ? item.title_ee : item.title_ru}</td>
              </tr>
            )
          } else {
            return <></>
          }
        })}
      </tbody>
    </table>
  )
}

export default Schedule
